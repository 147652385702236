import React from "react";
import { Box, Button, Flex, Image, Link, Spacer } from "@chakra-ui/react";
import Facebook from "./assets/social-media-icons/facebook_32x32.png";
import Twitter from "./assets/social-media-icons/twitter_32x32.png";
import Email from "./assets/social-media-icons/email_32x32.png";

const NavBar = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
    }
  }

  return (
    <Flex justify="space-between" align="center" padding="30px">
      {/* Left Side - Social Media Icons */}
      <Flex justify="space-around" width="75%">

      </Flex>
      <Flex justify="space-around" width="75%">

      </Flex>
      <Flex justify="space-around" width="75%">

      </Flex>

      {/* Right Side - Sections and Connect */}
      <Flex
        justify="space-around"
        align="center"
      >
        <Box margin="0 15px" className="menu"><a href="https://twitter.com/CollectBrandon" target="_blank">Twitter</a></Box>
        <Spacer />
        <Box margin="0 15px" className="menu"><a href="https://opensea.io/collection/brandon-digital-trading-cards-series-2" target="_blank">Opensea</a></Box>
        <Spacer />
      </Flex>

      {/* Connect */}
      {isConnected ? (
        <Box margin="0 15px">Connected</Box>
      ) : (
        <Button
          backgroundColor="#D6517D"
          borderRadius="5px"
          boxShadow="0px 2px 2px 1px #0F0F0F"
          color="white"
          cursor="pointer"
          fontFamily="Chivo"
          fontSize="18px"
          padding="15px"
          margin="0 15px"
          onClick={connectAccount}
        >
          Connect
        </Button>
      )}
    </Flex>
  );
};

export default NavBar;
