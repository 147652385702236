import { useState } from "react";
import { ethers, BigNumber } from "ethers";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import BDTC2NFT from "./BDTC2.json";
import logo from './assets/logo.png';

const BDTC2NFTAddress = "0x9F096Fa5fFE4247390740A4e89707Dcc11c4cF1B";

const MainMint = ({ accounts, setAccounts }) => {
  const [mintAmount, setMintAmount] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0)
  const isConnected = Boolean(accounts[0]);

  handleMinted();

  async function handleMinted() {
    const RPC = "https://polygon-mainnet.g.alchemy.com/v2/rOc90beGBLvBs7Us13MSgixhsUe-_apQ";
    const provider = new ethers.providers.JsonRpcProvider(RPC);
    const nftContract = new ethers.Contract(BDTC2NFTAddress, BDTC2NFT.abi, provider);
    let getTotalMinted = await nftContract.totalSupply();
    setTotalMinted(10000-getTotalMinted.toString());
  }

  async function handleMint() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        BDTC2NFTAddress,
        BDTC2NFT.abi,
        signer
      );
      try {
        const response = await contract.publicSaleMint(window.ethereum.selectedAddress, BigNumber.from(mintAmount), {
          value: ethers.utils.parseEther((10 * mintAmount).toString()),
        });
        console.log("response:", response);
        alert("Transaction submitted!");
        window.location.reload();
      } catch (err) {
        console.log("error", err);
        if (err.data.code = '-32000') {
          alert("Insufficient balance.");
        }
      }
    }
  }

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
    if (mintAmount >= 10) return;
    setMintAmount(mintAmount + 1);
  };

  return (
    <Flex justify="center" align="center">
      <Box width="520px">
        <div>
          <img className="logo" src={logo} />
          <Text
            fontSize="30px"
            letterSpacing="-5.5%"
            textShadow="0 2px 2px #000000"
          >
            Mint Price: 10 Matic
          </Text>
          <Text
            fontSize="30px"
            letterSpacing="-5.5%"
            textShadow="0 2px 2px #000000"
          >
            Remaining: {totalMinted}/10000
          </Text>
        </div>

        {isConnected ? (
          <div>
            <Flex align="center" justify="center">
              <Button
                backgroundColor="#D6517D"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handleDecrement}
              >
                -
              </Button>
              <Input
                readOnly
                fontFamily="inherit"
                width="100px"
                height="40px"
                textAlign="center"
                paddingLeft="19px"
                marginTop="10px"
                type="number"
                value={mintAmount}
              />
              <Button
                backgroundColor="#D6517D"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handleIncrement}
              >
                +
              </Button>
            </Flex>
            <Button
              backgroundColor="#D6517D"
              borderRadius="5px"
              boxShadow="0px 2px 2px 1px #0F0F0F"
              color="white"
              cursor="pointer"
              fontFamily="inherit"
              padding="15px"
              marginTop="10px"
              onClick={handleMint}
            >
              Mint Now
            </Button>
          </div>
        ) : (
          <Text
            marginTop="70px"
            fontSize="30px"
            letterSpacing="-5.5%"
            textShadow="0 3px #000000"
            color="#D6517D"
          >
            You must be connected to Mint.
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default MainMint;
